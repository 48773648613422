// Function to toggle the chat options visibility
function toggleChatOptions() {
  const chatOptionsContainer = document.getElementById("chatOptionsContainer");
  const chatWidgetContainer = document.getElementById("chatWidgetContainer");

  // Close chat widget if open
  chatWidgetContainer.style.display = "none";

  // Toggle options visibility
  if (chatOptionsContainer.style.display === "none" || chatOptionsContainer.style.display === "") {
      chatOptionsContainer.style.display = "block";
  } else {
      chatOptionsContainer.style.display = "none";
  }
}

// Function to open the selected chatbot in the iframe
function openChat(bot, src) {
  const chatWidgetContainer = document.getElementById("chatWidgetContainer");
  const chatIframe = document.getElementById("chatIframe");
  const chatOptionsContainer = document.getElementById("chatOptionsContainer");

  // Set the iframe source based on selected bot
  if (bot === 'lana') {
    chatIframe.src = src; // Lana bot URL
  } else if (bot === 'sammy') {
    chatIframe.src = src; // Help bot URL (replace with actual Help bot URL)
  } else if (bot === 'alex') {
    chatIframe.src = src; // Help bot URL (replace with actual Help bot URL)
  }

  // Hide options and show chat widget
  chatOptionsContainer.style.display = "none";
  chatWidgetContainer.style.display = "block";
}

window.toggleChatOptions = toggleChatOptions;
window.openChat = openChat;
